import { ReactNode, useEffect } from 'react';
import styles from './Modal.module.css';

interface Properties {
  children: ReactNode;
  onClose: () => void;
}

export const Modal = ({ children, onClose }: Properties) => {
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        onClose();
      }
    }

    document.body.style.overflow = 'hidden';
    window.addEventListener('keydown', onKeyDown);

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  return (
    <div className={styles.container}>
      <div className={styles.background} onClick={onClose} />
      <div className={styles.modal}>{children}</div>
    </div>
  );
};
