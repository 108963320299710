import { useRouter } from 'next/router';
import { useEffect } from 'react';

const RESTORE_SCROLL_POSITION_KEY = '15TWENTY_RESTORE_SCROLL_POSITION';

export function useRestoreScrollPosition(key: string = RESTORE_SCROLL_POSITION_KEY) {
  const router = useRouter();

  useEffect(() => {
    const item = window.sessionStorage.getItem(key);
    const scrollY = item ? JSON.parse(item) : undefined;

    if (scrollY) {
      window.scrollTo(0, scrollY);
    }
  }, [key]);

  useEffect(() => {
    function onRouteChangeStart() {
      window.sessionStorage.setItem(key, JSON.stringify(window.scrollY));
    }

    router.events.on('routeChangeStart', onRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [key, router.events]);
}
