import { useEffect, useRef, useState } from 'react';

export function usePlay() {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playingSource, setPlayingSource] = useState<string>();

  const audioElement = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioElement.current = new Audio();
    audioElement.current.addEventListener('play', () => setIsPlaying(true));
    audioElement.current.addEventListener('pause', () => setIsPlaying(false));
    audioElement.current.addEventListener('ended', () => setIsPlaying(false));

    return () => {
      audioElement.current?.pause();
      audioElement.current?.removeAttribute('src');
      audioElement.current?.load();
      audioElement.current = undefined;
    };
  }, []);

  function onPlay(preview?: string | null) {
    if (!preview || !audioElement?.current) {
      return;
    }

    if (audioElement?.current?.src !== preview) {
      setPlayingSource(preview);
      audioElement.current.pause();
      audioElement.current.src = preview;
      audioElement.current.load();
      audioElement.current.play();
    } else {
      if (isPlaying) {
        audioElement.current.pause();
      } else {
        audioElement.current.play();
      }
    }
  }

  return { onPlay, isPlaying, playingSource };
}
