import { AddPostThumbnail } from 'components/AddPostThumbnail';
import { formatDate } from 'utils/formatDate';
import { getPostsSortedByDay } from 'utils/getPostsSortedByDay';
import { InputMaybe, PostType, usePostsQuery } from 'generated/graphql';
import { Loader } from 'components/Loader';
import { MouseEventHandler, useCallback, useEffect, useMemo } from 'react';
import { PostThumbnail } from 'components/PostThumbnail';
import { Section } from 'components/Section';
import { usePlay } from 'utils/usePlay';
import { useUser } from 'components/UserProvider';

interface Properties {
  disableLoader?: boolean;
  offset: number;
  postsPerPage: number;
  setIsFetching: (value: boolean) => void;
  setShowModal: (value: boolean) => void;
  type?: InputMaybe<PostType>;
}

export function PostThumbnailsContainer({
  disableLoader,
  offset,
  postsPerPage,
  setIsFetching,
  setShowModal,
  type,
}: Properties) {
  const queryVariables = useMemo(
    () => ({
      first: postsPerPage,
      offset: offset * postsPerPage,
      type,
    }),
    [offset, postsPerPage, type]
  );

  const { data, refetch, isFetching } = usePostsQuery(queryVariables, { enabled: false });
  const { onPlay, isPlaying, playingSource } = usePlay();
  const { user } = useUser();

  const postsSortedPerDay = useMemo(() => getPostsSortedByDay(data?.posts), [data?.posts]);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching, setIsFetching]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onUpvoteWithoutSession: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      setShowModal(true);
    },
    [setShowModal]
  );

  if (!data && isFetching && !disableLoader) {
    return <Loader alignCenter />;
  }

  return (
    <>
      {postsSortedPerDay.map((posts, index) => (
        <Section key={posts[0]._id} title={formatDate({ date: posts[0].createdAt })}>
          {!!user && offset === 0 && index === 0 && <AddPostThumbnail />}
          {posts.map((post) => (
            <PostThumbnail
              isPlaying={isPlaying && playingSource === post.preview}
              key={post._id}
              onPlay={onPlay}
              onUpvoteSuccess={refetch}
              onUpvoteWithoutSession={onUpvoteWithoutSession}
              post={post}
            />
          ))}
        </Section>
      ))}
    </>
  );
}
