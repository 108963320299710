import { OnIntersectDiv } from 'components/OnIntersectDiv';
import { PostThumbnailsContainer } from 'components/PostThumbnailsContainer';
import { PostType } from 'generated/graphql';
import { useCallback, useRef, useState } from 'react';
import { useSessionStorage } from 'utils/useSessionStorage';

interface Properties {
  offsetKey: string;
  postsPerPage: number;
  setShowModal: (value: boolean) => void;
  type?: PostType;
}

export const InfinitePosts = ({ offsetKey, postsPerPage, setShowModal, type }: Properties) => {
  const [isFetching, setIsFetching] = useState(false);
  const [offsets, setOffsets] = useSessionStorage(offsetKey, [0]);

  const initialOffsets = useRef<number[]>(
    typeof window === 'undefined' ? [0] : JSON.parse(window.sessionStorage.getItem(offsetKey) || '[0]')
  );

  const onIntersect = useCallback(() => {
    const newOffset = offsets[offsets.length - 1] + 1;
    setOffsets((offsets) => [...offsets, newOffset]);
  }, [offsets, setOffsets]);

  return (
    <>
      {offsets.map((offset) => (
        <PostThumbnailsContainer
          disableLoader={initialOffsets.current.includes(offset)}
          key={offset}
          offset={offset}
          postsPerPage={postsPerPage}
          setIsFetching={setIsFetching}
          setShowModal={setShowModal}
          type={type}
        />
      ))}

      {!isFetching && <OnIntersectDiv onIntersect={onIntersect} />}
    </>
  );
};
