import { PostsQuery } from 'generated/graphql';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';

export function getPostsSortedByDay(posts: PostsQuery['posts'] = []) {
  const postsPerDate = posts.reduce((accumulator: Record<string, PostsQuery['posts']>, current) => {
    const hasSameKey = Object.keys(accumulator).find((key) =>
      isSameDay(
        parseISO(new Date(current.createdAt).toISOString()),
        parseISO(new Date(accumulator[key][0].createdAt).toISOString())
      )
    );

    if (hasSameKey) {
      accumulator[hasSameKey].push(current);
      return accumulator;
    } else {
      return { ...accumulator, [current.createdAt.toString()]: [current] };
    }
  }, {});

  return Object.keys(postsPerDate)
    .sort((a, b) => (a > b ? -1 : 1))
    .reduce((accumulator: PostsQuery['posts'][], current: string) => {
      const posts = postsPerDate[current];
      const sortedPosts = sortPostsByUpvotes(posts);
      return [...accumulator, sortedPosts];
    }, []);
}

function sortPostsByUpvotes(posts: PostsQuery['posts']) {
  const postsWithUpvotes = [...posts]
    .filter((post) => (post.upvotes || []).length > 0)
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    .sort((a, b) => {
      const aUpvotes = (a.upvotes || []).length;
      const bUpvotes = (b.upvotes || []).length;
      return aUpvotes === bUpvotes ? 0 : aUpvotes > bUpvotes ? -1 : 1;
    });

  const postsWithoutUpvotes = [...posts]
    .filter((post) => (post.upvotes || []).length === 0)
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

  return [...postsWithUpvotes, ...postsWithoutUpvotes];
}
