import { useEffect, useRef } from 'react';

interface Properties {
  onIntersect: () => void;
}

export const OnIntersectDiv = ({ onIntersect }: Properties) => {
  const containerReference = useRef(null);

  useEffect(() => {
    const container = containerReference.current;
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            onIntersect();
          }
        }
      },
      { rootMargin: '0px', threshold: 1 }
    );

    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, [onIntersect]);

  return <div ref={containerReference} />;
};
