import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { RichText } from 'components/RichText';
import styles from './RegisterModal.module.css';

interface Properties {
  onClose: () => void;
}

export const UpvotingRequiredModal = ({ onClose }: Properties) => (
  <Modal onClose={onClose}>
    <RichText>
      <h2>Upvoting requires an account...</h2>
      <p>15Twenty is thé hiphop community. Join us today to post tracks and albums, upvote and comment!</p>
    </RichText>
    <div className={styles.buttonContainer}>
      <Button href="/register">Create an account</Button>
    </div>
  </Modal>
);
