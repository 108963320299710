import { PostType } from 'generated/graphql';
import styles from './Tag.module.css';

interface Properties {
  children: string;
  type?: PostType;
}

const tagStyles: Record<PostType, string> = {
  [PostType.Album]: styles.album,
  [PostType.Track]: styles.track,
};

export const Tag = ({ children, type }: Properties) => (
  <div className={type ? [styles.tag, tagStyles[type]].filter(Boolean).join(' ') : styles.tag}>
    {children.charAt(0).toUpperCase()}
    {children.toLowerCase().slice(1)}
  </div>
);
