import { PostQuery, PostsQuery, PostType } from 'generated/graphql';

interface Properties {
  post: PostsQuery['posts'][0] | PostQuery['post'];
}

export const SchemaOrg = ({ post }: Properties) => (
  <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: generateSchemaOrg(post) }} />
);

function generateSchemaOrg({ artists, image, name, slug, type }: PostsQuery['posts'][0] | PostQuery['post']) {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': type === PostType.Album ? 'MusicAlbum' : 'MusicRecording',
    genre: 'hiphop',
    image,
    name,
    url: `https://15twenty.co/posts/${slug}`,
    byArtist: artists?.map(({ name }) => ({ '@type': 'MusicGroup', genre: 'hiphop', name })),
    ...(type === PostType.Album
      ? {
          albumProductionType: 'https://schema.org/StudioAlbum',
          albumReleaseType: 'https://schema.org/AlbumRelease',
          albumRelease: { '@type': 'MusicRelease', name },
        }
      : {}),
  });
}
