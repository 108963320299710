import { HTMLAttributes, ReactNode } from 'react';
import { Loader } from 'components/Loader';
import Link from 'next/link';
import styles from './Button.module.css';

interface Properties extends HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  children: ReactNode;
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  secondary?: boolean;
  small?: boolean;
}

export const Button = ({ children, disabled, href, loading, secondary, small, ...rest }: Properties) => {
  const classNames = [styles.button, secondary ? styles.secondary : undefined, small ? styles.small : undefined]
    .filter(Boolean)
    .join(' ');

  const inner = (
    <>
      <span className={loading ? styles.labelLoading : undefined}>{children}</span>
      {loading && (
        <span className={styles.loader}>
          <Loader alignCenter />
        </span>
      )}
    </>
  );

  if (href) {
    return (
      <Link href={href} className={classNames} {...rest}>
        {inner}
      </Link>
    );
  }

  return (
    <button className={classNames} disabled={disabled || loading} {...rest}>
      {inner}
    </button>
  );
};
