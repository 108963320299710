import { NumberBubble } from 'components/NumberBubble';
import { ReactNode } from 'react';
import { RichText } from 'components/RichText';
import styles from './Section.module.css';

interface Properties {
  children: ReactNode;
  label?: string;
  numberAmount?: number;
  title?: string;
}

export const Section = ({ children, numberAmount, label, title }: Properties) => (
  <section className={styles.container}>
    {title && (
      <header className={styles.header}>
        <RichText>
          <NumberBubble amount={numberAmount}>
            <h2>{title}</h2>
          </NumberBubble>
        </RichText>
        {label && <div className={styles.label}>{label}</div>}
      </header>
    )}
    {children}
  </section>
);
