import { Button } from 'components/Button';
import { PostThumbnailContent } from 'components/PostThumbnail';
import { PostType } from 'generated/graphql';
import styles from './AddPostThumbnail.module.css';

export const AddPostThumbnail = () => (
  <div className={styles.container}>
    <PostThumbnailContent
      artists={[{ name: 'Biggie' }, { name: 'Tupac' }]}
      comments={[]}
      name="The Next Best Thing"
      type={PostType.Album}
    />
    <div className={styles.buttonContainer}>
      <Button href="/posts/add">Add a track or album</Button>
    </div>
  </div>
);
