import { Loader } from 'components/Loader';
import { MouseEventHandler } from 'react';
import { UpIcon } from 'components/Icons';
import styles from './SmallUpvoteButton.module.css';

interface Properties {
  active?: boolean;
  amount: number;
  loading?: boolean;
  onClick: MouseEventHandler;
}

export const SmallUpvoteButton = ({ active, amount = 0, loading, onClick }: Properties) => (
  <button
    className={active ? [styles.button, styles.active].join(' ') : styles.button}
    disabled={loading}
    onClick={onClick}
  >
    <span className={styles.inner}>
      <UpIcon />
      {loading ? (
        <span className={styles.loader}>
          <Loader />
        </span>
      ) : (
        <span>{amount.toString()}</span>
      )}
    </span>
  </button>
);
