import { captureException } from '@sentry/nextjs';
import { useCallback, useEffect, useState } from 'react';

export function useSessionStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  useEffect(() => {
    const item = window.sessionStorage.getItem(key);

    if (item) {
      setStoredValue(JSON.parse(item));
    }
  }, [key]);

  const setValue = useCallback(
    (value: T | ((value_: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);

        if (typeof window !== 'undefined') {
          window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        captureException(error);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue] as const;
}
