import { captureException } from 'utils/sentry';
import { MouseEvent, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react';
import { PlayIcon, PauseIcon } from 'components/Icons';
import { PostsQuery, PostType, useAddOrRemoveUpvoteMutation } from 'generated/graphql';
import { SchemaOrg } from 'components/SchemaOrg';
import { SmallUpvoteButton } from 'components/SmallUpvoteButton';
import { Tag } from 'components/Tag';
import { useUser } from 'components/UserProvider';
import Image from 'next/legacy/image';
import Link from 'next/link';
import styles from './PostThumbnail.module.css';

interface Properties {
  isPlaying: boolean;
  onPlay: (preview?: string | null) => void;
  onUpvoteSuccess: () => void;
  onUpvoteWithoutSession: MouseEventHandler<HTMLButtonElement>;
  post: PostsQuery['posts'][0];
}

export const PostThumbnail = ({ isPlaying, onPlay, onUpvoteSuccess, onUpvoteWithoutSession, post }: Properties) => {
  const { artists, comments, image, name, preview, slug, type, upvotes } = post;
  const { user } = useUser();

  const { isLoading, mutateAsync: addOrRemoveUpvote } = useAddOrRemoveUpvoteMutation();

  const onUpvote = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        event.preventDefault();
        await addOrRemoveUpvote({ slug });
        onUpvoteSuccess();
      } catch (error) {
        captureException(error);
      }
    },
    [addOrRemoveUpvote, onUpvoteSuccess, slug]
  );

  const onPlayButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onPlay(preview);
    },
    [onPlay, preview]
  );

  const actionElement = useMemo(
    () => (
      <SmallUpvoteButton
        active={upvotes?.some(({ createdBy }) => createdBy._id === user?._id)}
        amount={upvotes?.length || 0}
        loading={isLoading}
        onClick={user ? onUpvote : onUpvoteWithoutSession}
      />
    ),
    [isLoading, onUpvote, onUpvoteWithoutSession, upvotes, user]
  );

  return (
    <>
      <SchemaOrg post={post} />
      <Link href={`/posts/${slug}`} className={styles.container}>
        <PostThumbnailContent
          actionElement={actionElement}
          artists={artists}
          comments={comments}
          image={image}
          isPlaying={isPlaying}
          name={name}
          onPlayButtonClick={onPlayButtonClick}
          preview={preview}
          type={type}
        />
      </Link>
    </>
  );
};

interface PostThumbnailContentProperties {
  actionElement?: ReactNode;
  artists?: PostsQuery['posts'][0]['artists'];
  comments: PostsQuery['posts'][0]['comments'];
  image?: string;
  isPlaying?: boolean;
  name: string;
  onPlayButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  preview?: PostsQuery['posts'][0]['preview'];
  type: PostType;
}

export const PostThumbnailContent = ({
  actionElement,
  artists,
  comments,
  image,
  isPlaying,
  name,
  onPlayButtonClick,
  preview,
  type,
}: PostThumbnailContentProperties) => {
  const commentsPlural = !!comments && (comments.length === 0 || comments?.length > 1);

  return (
    <div className={styles.content}>
      <div className={styles.imageContentContainer}>
        <div className={styles.imageContainer}>
          {image && <Image layout="fixed" alt={name} width={64} height={64} quality={50} src={image} />}
          {preview && (
            <button aria-label={isPlaying ? 'Pause' : 'Play'} className={styles.playButton} onClick={onPlayButtonClick}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>
          )}
        </div>
        <div className={styles.contentContainer}>
          <h3>{name}</h3>
          <p>{artists?.map(({ name }) => name).join(', ')}</p>
          <div className={styles.tags}>
            <Tag type={type}>{type}</Tag>
            <Tag>{`${comments?.length || 0} comment${commentsPlural ? 's' : ''}`}</Tag>
          </div>
        </div>
      </div>
      {actionElement}
    </div>
  );
};
